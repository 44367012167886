import React from "react";
import { CiReceipt } from "react-icons/ci";
import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useToast } from "@chakra-ui/react";
import moment from "moment";
import Loader from "../components/Loader";
import { IoMdArrowRoundBack } from "react-icons/io";
import { useCookies } from "react-cookie";

const BiltyDetails = () => {
  const toast = useToast();
  const location = useLocation();
  const [biltyId, setBiltyId] = useState();
  const [date, setDate] = useState(new Date().toISOString().substring(0, 10));
  const [branch, setBranch] = useState("");
  const [driverName, setDriverName] = useState("");
  const [truckNo, setTruckNo] = useState("");
  const [from, setFrom] = useState("");
  const [to, setTo] = useState("");
  const [consigneeName, setConsigneeName] = useState("");
  const [consigneeAddress, setConsigneeAddress] = useState("");
  const [consignerName, setConsignerName] = useState("");
  const [consignerAddress, setConsignerAddress] = useState("");
  const [quantity, setQuantity] = useState();
  const [description, setDescription] = useState("");
  const [invoiceNo, setInvoiceNo] = useState("");
  const [actualWeight, setActualWeight] = useState("");
  const [chargedWeight, setChargedWeight] = useState("");
  const [fright, setFright] = useState("");
  const [hamali, setHamali] = useState("");
  const [surCh, setSurCh] = useState("");
  const [stCh, setStCh] = useState("");
  const [aoc, setAoc] = useState("");
  const [total, setTotal] = useState("");

  const [fetchingBilty, setFetchingBilty] = useState(false);

  const navigate = useNavigate();
  const [cookies] = useCookies();

  const fetchBiltyDetails = async (biltyId) => {
    try {
      setFetchingBilty(true);
      const baseUrl = process.env.REACT_APP_BACKEND_URL;
      const response = await fetch(baseUrl + `bilty/get?_id=${biltyId}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${cookies?.token}`,
        },
      });
      const data = await response.json();
      if (!data.success) {
        throw new Error(data.message);
      }
      setDate(new Date(data?.date).toISOString().substring(0, 10));
      setBranch(data?.branch || "N/A");
      setFrom(data?.from || "N/A");
      setTo(data?.to || "N/A");
      setDriverName(data?.driver_name || "N/A");
      setTruckNo(data?.truck_no || "N/A");
      setConsignerName(data?.consigner_name || "N/A");
      setConsigneeName(data?.consignee_name || "N/A");
      setConsignerAddress(data?.consigner_address || "N/A");
      setConsigneeAddress(data?.consignee_address || "N/A");
      setQuantity(data?.quantity || "N/A");
      setDescription(data?.description || "N/A");
      setInvoiceNo(data?.invoice_no || "N/A");
      setActualWeight(data?.actual_weight || "N/A");
      setChargedWeight(data?.charged_weight || "N/A");
      setFright(data?.fright || "N/A");
      setHamali(data?.hamali || "N/A");
      setSurCh(data?.sur_ch || "N/A");
      setStCh(data?.st_ch || "N/A");
      setAoc(data?.aoc || "N/A");
      setTotal(data?.total || "N/A");
    } catch (error) {
      toast({
        status: "error",
        description: error.message,
        position: "top",
      });
    } finally {
      setFetchingBilty(false);
    }
  };

  useEffect(() => {
    if (location?.state?.biltyId) {
      setBiltyId(location?.state?.biltyId);
      fetchBiltyDetails(location?.state?.biltyId);
    }
  }, []);

  return (
    <div className="py-5 px-5 h-[inherit] overflow-auto">
      <h1 className="text-2xl md:text-4xl mt-5 mb-10 font-bold flex gap-2">
        <IoMdArrowRoundBack onClick={() => navigate("/dashboard/")} />
        Bilty Details
      </h1>

      {fetchingBilty && <Loader />}
      {!fetchingBilty && (
        <div>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-2 my-2">
            <div>
              <p className="font-medium">Date</p>
              <p>{moment(date).format("DD-MM-YYYY")}</p>
            </div>
            <div>
              <p className="font-medium">Branch</p>
              <p>{branch}</p>
            </div>
            <div>
              <p className="font-medium">Driver Name</p>
              <p>{driverName}</p>
            </div>
            <div>
              <p className="font-medium">Truck No.</p>
              <p>{truckNo}</p>
            </div>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-2 my-2">
            <div>
              <p className="font-medium">From</p>
              <p>{from}</p>
            </div>
            <div>
              <p className="font-medium">To</p>
              <p>{to}</p>
            </div>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-2 my-2">
            <div>
              <p className="font-medium">Consigner Name</p>
              <p>{consigneeName}</p>
            </div>
            <div>
              <p className="font-medium">Consignee Name</p>
              <p>{consigneeName}</p>
            </div>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-2 my-2">
            <div>
              <p className="font-medium">Consigner Address</p>
              <p>{consigneeAddress}</p>
            </div>
            <div>
              <p className="font-medium">Consignee Address</p>
              <p>{consigneeAddress}</p>
            </div>
          </div>

          <div className="mt-10 grid-cols-1 md:grid-cols-2 gap-2 my-2">
            <div>
              <p className="font-medium">Quantity</p>
              <p>{quantity}</p>
            </div>
            <div>
              <p className="font-medium">Description</p>
              <p>{description}</p>
            </div>
            <div>
              <p className="font-medium">Invoice No.</p>
              <p>{invoiceNo}</p>
            </div>
          </div>
          <p className="font-medium my-2">Weight (In Tonne)</p>
          <div className="gri grid-cols-1 md:grid-cols-2 gap-2">
            <div>
              <p className="text-sm font-medium">Actual</p>
              <p>{actualWeight}</p>
            </div>
            <div>
              <p className="text-sm font-mono">Charged</p>
              <p>{chargedWeight}</p>
            </div>
          </div>

          <div className="flex flex-col w-[20rem] items-center space-y-1 my-2">
            <div className="flex w-full justify-start mb-2 font-semibold">
              <div className="w-[5rem] md:w-[7rem] font-medium">Quantity</div>
              <div className="font-medium">Amount to Pay/Paid/Due</div>
            </div>
            <div className="flex w-full justify-start">
              <p className="text-sm w-[5rem] md:w-[7rem] font-medium">Fright</p>
              <p>{fright}</p>
            </div>
            <div className="flex w-full justify-start">
              <p className="text-sm w-[5rem] md:w-[7rem]">Hamali</p>
              <p>{hamali}</p>
            </div>
            <div className="flex w-full justify-start">
              <p className="text-sm w-[5rem] md:w-[7rem] font-medium">
                Sur. Ch.
              </p>
              <p>{surCh}</p>
            </div>
            <div className="flex w-full justify-start">
              <p className="text-sm w-[5rem] md:w-[7rem] font-medium">
                St. Ch.
              </p>
              <p>{stCh}</p>
            </div>
            <div className="flex w-full justify-start">
              <p className="text-sm w-[5rem] md:w-[7rem] font-medium">A.O.C.</p>
              <p>{aoc}</p>
            </div>
            <div className="flex w-full justify-start">
              <p className="text-sm w-[5rem] md:w-[7rem] font-medium">Total</p>
              <p>{total}</p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default BiltyDetails;
