import {
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  Textarea,
  useToast,
} from "@chakra-ui/react";
import React from "react";
import { CiReceipt } from "react-icons/ci";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { IoMdArrowRoundBack } from "react-icons/io";
import { useCookies } from "react-cookie";

const CreateBilty = () => {
  const toast = useToast();

  const [date, setDate] = useState(new Date().toISOString().substring(0, 10));
  const [branch, setBranch] = useState("");
  const [driverName, setDriverName] = useState("");
  const [truckNo, setTruckNo] = useState("");
  const [from, setFrom] = useState("");
  const [to, setTo] = useState("");
  const [consigneeName, setConsigneeName] = useState("");
  const [consigneeAddress, setConsigneeAddress] = useState("");
  const [consignerName, setConsignerName] = useState("");
  const [consignerAddress, setConsignerAddress] = useState("");
  const [quantity, setQuantity] = useState();
  const [description, setDescription] = useState("");
  const [invoiceNo, setInvoiceNo] = useState("");
  const [actualWeight, setActualWeight] = useState("");
  const [chargedWeight, setChargedWeight] = useState("");
  const [fright, setFright] = useState("");
  const [hamali, setHamali] = useState("");
  const [surCh, setSurCh] = useState("");
  const [stCh, setStCh] = useState("");
  const [aoc, setAoc] = useState("");
  const [total, setTotal] = useState("");

  const [creatingBilty, setCreatingBilty] = useState(false);

  const navigate = useNavigate();
  const [cookies] = useCookies();

  const createBiltyHandler = async (e) => {
    e.preventDefault();

    const body = {
      date: date,
      branch: branch,
      driver_name: driverName,
      truck_no: truckNo,
      from: from,
      to: to,
      consignee_name: consigneeName,
      consigner_address: consignerAddress,
      consigner_name: consignerName,
      consignee_address: consignerAddress,
      quantity: quantity,
      description: description,
      invoice_no: invoiceNo,
      actual_weight: actualWeight,
      charged_weight: chargedWeight,
      fright: fright,
      hamali: hamali,
      sur_ch: surCh,
      st_ch: stCh,
      aoc: aoc,
      total: total,
    };

    try {
      setCreatingBilty(true);
      const base_url = process.env.REACT_APP_BACKEND_URL;
      const response = await fetch(base_url + "bilty/create", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${cookies?.token}`,
        },
        body: JSON.stringify({ ...body }),
      });
      const data = await response.json();
      if (!data.success) {
        throw new Error(data.message);
      }

      toast({
        status: "success",
        description: data?.message || "Bilty has been created successfully",
        position: "top",
      });
      navigate("/dashboard/");
    } catch (err) {
      toast({
        status: "error",
        description: err?.message || "Something went wrong",
        position: "top",
      });
    } finally {
      setCreatingBilty(false);
    }
  };

  const handleMaxChars = (maxChars, field, value, setFunc) => {
    if (value.length > maxChars) {
      toast({
        status: "error",
        description: `${field} field can contain max ${maxChars} characters`,
        position: "top",
      });
      return;
    }
    setFunc(value);
  };

  return (
    <div className="py-5 px-5 h-[inherit] overflow-auto">
      <h1 className="text-2xl md:text-4xl mt-5 mb-10 font-bold flex gap-2">
        <IoMdArrowRoundBack onClick={() => navigate("/dashboard/")} />
        Create Bilty
      </h1>

      <form onSubmit={createBiltyHandler}>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-2 my-2">
          <FormControl>
            <FormLabel>Date</FormLabel>
            <Input
              value={date}
              onChange={(e) => setDate(e.target.value)}
              type="date"
              min={new Date().toISOString().substring(0, 10)}
            />
          </FormControl>
          <FormControl isRequired={true}>
            <FormLabel>Branch</FormLabel>
            <Input
              value={branch}
              onChange={(e) =>
                handleMaxChars(18, "Branch", e.target.value, setBranch)
              }
              type="text"
            />
          </FormControl>
          <FormControl isRequired={true}>
            <FormLabel>Driver Name</FormLabel>
            <Input
              value={driverName}
              onChange={(e) =>
                handleMaxChars(18, "Driver Name", e.target.value, setDriverName)
              }
              type="text"
            />
          </FormControl>
          <FormControl isRequired={true}>
            <FormLabel>Truck No.</FormLabel>
            <Input
              value={truckNo}
              onChange={(e) =>
                handleMaxChars(18, "Truck No.", e.target.value, setTruckNo)
              }
              type="text"
            />
          </FormControl>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-2 my-2">
          <FormControl isRequired={true}>
            <FormLabel>From</FormLabel>
            <Input
              value={from}
              onChange={(e) =>
                handleMaxChars(18, "From", e.target.value, setFrom)
              }
              type="text"
            />
          </FormControl>
          <FormControl isRequired={true}>
            <FormLabel>To</FormLabel>
            <Input
              value={to}
              onChange={(e) => handleMaxChars(18, "To", e.target.value, setTo)}
              type="text"
            />
          </FormControl>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-2 my-2">
          <FormControl isRequired={true}>
            <FormLabel>Consigner Name</FormLabel>
            <Input
              value={consignerName}
              onChange={(e) =>
                handleMaxChars(
                  18,
                  "Consigner Name",
                  e.target.value,
                  setConsignerName
                )
              }
              type="text"
            />
          </FormControl>
          <FormControl isRequired={true}>
            <FormLabel>Consignee Name</FormLabel>
            <Input
              value={consigneeName}
              onChange={(e) =>
                handleMaxChars(
                  18,
                  "Consignee Name",
                  e.target.value,
                  setConsigneeName
                )
              }
              type="text"
            />
          </FormControl>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-2 my-2">
          <FormControl isRequired={true}>
            <FormLabel>Consigner Address</FormLabel>
            <Textarea
              value={consignerAddress}
              onChange={(e) =>
                handleMaxChars(
                  50,
                  "Consigner Address",
                  e.target.value,
                  setConsignerAddress
                )
              }
            />
          </FormControl>
          <FormControl isRequired={true}>
            <FormLabel>Consignee Address</FormLabel>
            <Textarea
              value={consigneeAddress}
              onChange={(e) =>
                handleMaxChars(
                  50,
                  "Consignee Address",
                  e.target.value,
                  setConsigneeAddress
                )
              }
            />
          </FormControl>
        </div>

        <div className="mt-10 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-2 my-2">
          <FormControl>
            <FormLabel>Quantity</FormLabel>
            <Input
              value={quantity}
              onChange={(e) => setQuantity(e.target.value)}
              type="number"
            />
          </FormControl>
          <FormControl>
            <FormLabel>Description</FormLabel>
            <Textarea
              value={description}
              onChange={(e) =>
                handleMaxChars(
                  100,
                  "Description",
                  e.target.value,
                  setDescription
                )
              }
            />
          </FormControl>
          <FormControl>
            <FormLabel>Invoice No.</FormLabel>
            <Input
              value={invoiceNo}
              onChange={(e) =>
                handleMaxChars(15, "Invoice No.", e.target.value, setInvoiceNo)
              }
              type="text"
            />
          </FormControl>
        </div>
        <p className="font-semibold my-2">Weight (In Tonne)</p>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-2">
          <FormControl>
            <FormLabel className="text-sm">Actual</FormLabel>
            <Input
              value={actualWeight}
              onChange={(e) => setActualWeight(e.target.value)}
              type="number"
            />
          </FormControl>
          <FormControl>
            <FormLabel className="text-sm">Charged</FormLabel>
            <Input
              value={chargedWeight}
              onChange={(e) => setChargedWeight(e.target.value)}
              type="number"
            />
          </FormControl>
        </div>

        <div className="flex flex-col w-[20rem] items-center space-y-1 my-2">
          <div className="flex w-full justify-start mb-2 font-semibold">
            <div className="w-[7rem]">Quantity</div>
            <div className="">Amount to Pay/Paid/Due</div>
          </div>
          <FormControl className="flex">
            <FormLabel className="text-sm w-[10rem]">Fright</FormLabel>
            <Input
              value={fright}
              onChange={(e) =>
                handleMaxChars(10, "Fright", e.target.value, setFright)
              }
              type="text"
            />
          </FormControl>
          <FormControl className="flex">
            <FormLabel className="text-sm w-[10rem]">Hamali</FormLabel>
            <Input
              value={hamali}
              onChange={(e) =>
                handleMaxChars(10, "Hamali", e.target.value, setHamali)
              }
              type="text"
            />
          </FormControl>
          <FormControl className="flex">
            <FormLabel className="text-sm w-[10rem]">Sur. Ch.</FormLabel>
            <Input
              value={surCh}
              onChange={(e) =>
                handleMaxChars(10, "Sur. Ch.", e.target.value, setSurCh)
              }
              type="text"
            />
          </FormControl>
          <FormControl className="flex">
            <FormLabel className="text-sm w-[10rem]">St. Ch.</FormLabel>
            <Input
              value={stCh}
              onChange={(e) =>
                handleMaxChars(10, "St. Ch.", e.target.value, setStCh)
              }
              type="text"
            />
          </FormControl>
          <FormControl className="flex">
            <FormLabel className="text-sm w-[10rem]">A.O.C.</FormLabel>
            <Input
              value={aoc}
              onChange={(e) =>
                handleMaxChars(10, "St. Ch.", e.target.value, setAoc)
              }
              type="text"
            />
          </FormControl>
          <FormControl className="flex">
            <FormLabel className="text-s w-[10rem]">Total</FormLabel>
            <Input
              value={total}
              onChange={(e) =>
                handleMaxChars(10, "Total", e.target.value, setTotal)
              }
              type="text"
            />
          </FormControl>
        </div>

        <div className="mt-5 mb-3">
          <Button
            isLoading={creatingBilty}
            type="submit"
            width={"100%"}
            rightIcon={<CiReceipt size={25} />}
            colorScheme="blue"
          >
            Create Bilty
          </Button>
        </div>
      </form>
    </div>
  );
};

export default CreateBilty;
