import React, { useEffect, useState } from "react";
import Table from "../components/Table";
import { filter, useToast } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import Loader from "../components/Loader";
import { useCookies } from "react-cookie";

const columns = [
  {
    Header: "Challan No.",
    accessor: "challan_no",
  },
  {
    Header: "Bilty No.",
    accessor: "bilty_no",
  },
  {
    Header: "From",
    accessor: "from",
  },
  {
    Header: "To",
    accessor: "to",
  },
  {
    Header: "Weight (In Tonne)",
    accessor: "weight",
  },
  {
    Header: "Date",
    accessor: "date",
  },
];

const AllChallan = () => {
  const toast = useToast();
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [searchKey, setSearchKey] = useState("");
  const base_url = process.env.REACT_APP_BACKEND_URL + "challan/";
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [cookies] = useCookies();

  const fetchAllChallans = async () => {
    try {
      setIsLoading(true);
      const response = await fetch(base_url + "all", {
        method: "GET",
        headers: {
          Authorization: `Bearer ${cookies?.token}`,
        },
      });
      const data = await response.json();
      if (!data.success) {
        throw new Error(data.message);
      }
      setData(data.challans);
      setFilteredData(data.challans);
    } catch (err) {
      toast({
        status: "error",
        description: err.message,
        position: "top",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const downloadChallanHandler = (id) => {
    toast({
      status: "warning",
      position: "top",
      description: "Download started...",
    });
    fetch(base_url + `download?id=${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${cookies?.token}`,
      },
    })
      .then((response) => {
        const filename = response.headers
          .get("content-disposition")
          .split("filename=")[1]
          .replace(/"/g, "");
        return response.blob().then((blob) => ({ filename, blob }));
      })
      .then(({ filename, blob }) => {
        var a = document.createElement("a");
        a.href = window.URL.createObjectURL(blob);
        a.download = filename;
        a.click();
        a.remove();
        toast({
          status: "success",
          position: "top",
          description: "Challan downloaded",
        });
      })
      .catch((error) => {
        toast({ status: "error", position: "top", description: error.message });
      });
  };

  const viewChallanHandler = (challanId) => {
    navigate("/dashboard/challan-details", { state: { challanId: challanId } });
  };

  const editChallanHandler = (challanId) => {
    navigate("/dashboard/edit-challan", { state: { challanId: challanId } });
  };

  useEffect(() => {
    fetchAllChallans();
  }, []);

  useEffect(() => {
    const searchResults = data.filter(
      (challan) =>
        challan?.bilty_no
          ?.toString()
          ?.toLowerCase()
          ?.includes(searchKey?.toLowerCase()) ||
        challan?.challan_no
          ?.toLowerCase()
          ?.includes(searchKey?.toLowerCase()) ||
        challan?.weight?.toLowerCase()?.includes(searchKey?.toLowerCase()) ||
        challan?.to?.toLowerCase()?.includes(searchKey?.toLowerCase()) ||
        challan?.from?.toLowerCase()?.includes(searchKey?.toLowerCase()) ||
        (challan?.date &&
          new Date(challan?.date)
            ?.toISOString()
            ?.substring(0, 10)
            ?.split("-")
            .reverse()
            .join("")
            ?.includes(searchKey.replaceAll("-", "")))
    );
    setFilteredData(searchResults);
  }, [searchKey]);

  return (
    <div className="py-5 px-5">
      <h1 className="text-4xl mt-5 mb-6 font-bold lg:px-[50px]">Challan</h1>

      <div className="flex lg:px-[50px] mb-7">
        <input
          value={searchKey}
          onChange={(e) => setSearchKey(e.target.value)}
          className="w-[20rem] border outline-none px-4 pt-[6px] pb-1 rounded"
          placeholder="Search a Challan"
        ></input>
      </div>

      {isLoading && <Loader />}
      <div className="w-[90%] mx-auto overflow-auto">
        {!isLoading &&
          filteredData.length === 0 &&
          searchKey?.trim() !== "" && (
            <div className="text-xl text-center font-medium">
              No match found!
            </div>
          )}
        {!isLoading &&
          filteredData.length === 0 &&
          searchKey?.trim() === "" && (
            <div className="text-xl text-center font-medium">
              No challan found!
            </div>
          )}
        {!isLoading && filteredData.length > 0 && (
          <Table
            columns={columns}
            data={filteredData}
            downloadHandler={downloadChallanHandler}
            viewHandler={viewChallanHandler}
            editHandler={editChallanHandler}
          />
        )}
      </div>
    </div>
  );
};

export default AllChallan;
