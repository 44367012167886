import React, { useEffect, useState } from "react";
import { CiReceipt } from "react-icons/ci";
import { TbReceiptRupee } from "react-icons/tb";
import { useToast } from "@chakra-ui/react";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import Loader from "../components/Loader";
import { IoMdArrowRoundBack } from "react-icons/io";
import { useCookies } from "react-cookie";

const ChallanDetails = () => {
  const toast = useToast();
  const location = useLocation();
  const [challanId, setChallanId] = useState();
  const [challanNo, setChallanNo] = useState("");
  const [biltyNo, setBiltyNo] = useState("");
  const [from, setFrom] = useState("");
  const [to, setTo] = useState("");
  const [lorryNo, setLorryNo] = useState("");
  const [totalPackages, setTotalPackages] = useState();
  const [weight, setWeight] = useState();
  const [freight, setFright] = useState();
  const [totalFreight, setTotalFright] = useState();
  const [advancePaid, setAdvancePaid] = useState();
  const [balancePaid, setBalancePaid] = useState();
  const [toBePaidAt, setToBePaidAt] = useState("");
  const [driverName, setDriverName] = useState("");
  const [driverPhone, setDriverPhone] = useState("");
  const [ownerName, setOwnerName] = useState("");
  const [ownerAddress, setOwnerAddress] = useState("");
  const [engineNo, setEngineNo] = useState("");
  const [chessisNo, setChessisNo] = useState("");
  const [panCardNo, setPanCardNo] = useState("");
  const [agentBrokerNameAndAddress, setAgentBrokerNameAndAddress] =
    useState("");
  const [date, setDate] = useState(new Date().toISOString().substring(0, 10));
  const [contents, setContents] = useState("");
  const [destination, setDestination] = useState("");
  const [bookingStation, setBookingStation] = useState("");

  const [fetchingChallan, setFetchingChallan] = useState(false);

  const navigate = useNavigate();
  const [cookies] = useCookies();

  const fetchChallanDetails = async (challanId) => {
    try {
      setFetchingChallan(true);
      const baseUrl = process.env.REACT_APP_BACKEND_URL;
      const response = await fetch(baseUrl + `challan/get?id=${challanId}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${cookies?.token}`,
        },
      });
      const data = await response.json();
      if (!data.success) {
        throw new Error(data.message);
      }
      setChallanNo(data?.challan?.challan_no);
      setBiltyNo(data?.challan?.bilty_no);
      setFrom(data?.challan?.from);
      setTo(data?.challan?.to);
      setLorryNo(data?.challan?.lorry_no);
      setTotalPackages(data?.challan?.total_packages);
      setWeight(data?.challan?.weight);
      setFright(data?.challan?.freight);
      setFright(data?.challan?.freight);
      setTotalFright(data?.challan?.total_freight);
      setAdvancePaid(data?.challan?.advance_paid);
      setBalancePaid(data?.challan?.balance_paid);
      setToBePaidAt(data?.challan?.to_be_paid_at);
      setDriverName(data?.challan?.driver_name);
      setDriverPhone(data?.challan?.driver_phone);
      setOwnerName(data?.challan?.owner_name);
      setOwnerAddress(data?.challan?.owner_address);
      setEngineNo(data?.challan?.engine_no);
      setChessisNo(data?.challan?.chessis_no);
      setPanCardNo(data?.challan?.pan_card_no);
      setAgentBrokerNameAndAddress(data?.challan?.agent_broker_name_address);
      setDate(data?.challan?.date);
      setContents(data?.challan?.contents);
      setDestination(data?.challan?.destination);
      setBookingStation(data?.challan?.booking_station);
    } catch (error) {
      toast({
        status: "error",
        description: error.message,
        position: "top",
      });
    } finally {
      setFetchingChallan(false);
    }
  };

  useEffect(() => {
    if (location?.state?.challanId) {
      setChallanId(location?.state?.challanId);
      fetchChallanDetails(location?.state?.challanId);
    }
  }, []);

  return (
    <div className="py-5 px-5 h-[inherit] overflow-auto">
      <h1 className="text-2xl md:text-4xl mt-5 mb-6 font-bold flex gap-2">
        <IoMdArrowRoundBack
          onClick={() => navigate("/dashboard/all-challan")}
        />
        Challan Details
      </h1>

      {fetchingChallan && <Loader />}
      {!fetchingChallan && (
        <div>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-2">
            <div>
              <p className="font-medium">Bilty No.</p>
              <p>{biltyNo}</p>
            </div>
            <div>
              <p className="font-medium">Challan No.</p>
              <p>{challanNo}</p>
            </div>
          </div>

          <div className="mt-8">
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-2 mb-1 mt-2">
              <div>
                <p className="font-medium">From</p>
                <p>{from}</p>
              </div>
              <div>
                <p className="font-medium">To</p>
                <p>{to}</p>
              </div>
              <div>
                <p className="font-medium">Lorry No.</p>
                <p>{lorryNo}</p>
              </div>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-2 mb-1 mt-2">
              <div>
                <p className="font-medium">Date</p>
                <p>{moment(date).format("DD-MM-YYYY")}</p>
              </div>
              <div>
                <p className="font-medium">Contents</p>
                <p>{contents}</p>
              </div>
              <div>
                <p className="font-medium">Destination</p>
                <p>{destination}</p>
              </div>
              <div>
                <p className="font-medium">Booking Station</p>
                <p>{bookingStation}</p>
              </div>
              <div>
                <p className="font-medium">Total Packages</p>
                <p>{totalPackages}</p>
              </div>
              <div>
                <p className="font-medium">Weight (In Tonne)</p>
                <p>{weight}</p>
              </div>
              <div>
                <p className="font-medium">Freight</p>
                <p>{freight}</p>
              </div>
              <div>
                <p className="font-medium">Total Freight (Rs)</p>
                <p>{totalFreight}</p>
              </div>
              <div>
                <p className="font-medium">Advance Paid (Rs)</p>
                <p>{advancePaid}</p>
              </div>
              <div>
                <p className="font-medium">Balance Paid (Rs)</p>
                <p>{balancePaid}</p>
              </div>
              <div>
                <p className="font-medium">To Be Paid At</p>
                <p>{toBePaidAt}</p>
              </div>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-2 mb-1 mt-2">
              <div>
                <p className="font-medium">Driver's Name</p>
                <p>{driverName}</p>
              </div>
              <div>
                <p className="font-medium">Driver's Phone No.</p>
                <p>{driverPhone}</p>
              </div>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-2 mb-1 mt-2">
              <div>
                <p className="font-medium">Owner's Name</p>
                <p>{ownerName}</p>
              </div>
              <div>
                <p className="font-medium">Owner's Address</p>
                <p>{ownerAddress}</p>
              </div>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-2 mb-1 mt-2">
              <div>
                <p className="font-medium">Engine No.</p>
                <p>{engineNo}</p>
              </div>
              <div>
                <p className="font-medium">Chessis No.</p>
                <p>{chessisNo}</p>
              </div>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-2 mb-1 mt-2">
              <div>
                <p className="font-medium">PAN Card No.</p>
                <p>{panCardNo}</p>
              </div>
              <div>
                <p className="font-medium">Agent's/Broker's Name & Address</p>
                <p>{agentBrokerNameAndAddress}</p>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ChallanDetails;
