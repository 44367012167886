import { Button, div, p, Input, Textarea } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { CiReceipt } from "react-icons/ci";
import { TbReceiptRupee } from "react-icons/tb";
import { useToast } from "@chakra-ui/react";
import { useLocation, useNavigate } from "react-router-dom";
import { LiaFileInvoiceSolid } from "react-icons/lia";
import moment from "moment";
import Loader from "../components/Loader";
import { IoMdArrowRoundBack } from "react-icons/io";
import { useCookies } from "react-cookie";

const BillDetails = () => {
  const toast = useToast();
  const [billedToName, setBilledToName] = useState("");
  const [billedToAddress, setBilledToAddress] = useState("");
  const [challanNo, setChallanNo] = useState("");
  const [billNo, setBillNo] = useState("");
  const [biltyNo, setBiltyNo] = useState("");
  const [from, setFrom] = useState("");
  const [to, setTo] = useState("");
  const [lorryNo, setLorryNo] = useState("");
  const [weight, setWeight] = useState();
  const [total, setTotal] = useState();
  const [advancePaid, setAdvancePaid] = useState();
  const [balancePaid, setBalancePaid] = useState();
  const [driverName, setDriverName] = useState("");
  const [driverPhone, setDriverPhone] = useState("");
  const [date, setDate] = useState(new Date().toISOString().substring(0, 10));
  const [contents, setContents] = useState("");
  const [bookingStation, setBookingStation] = useState("");

  const [priceType, setPriceType] = useState("Fixed");
  const [priceTotal, setPriceTotal] = useState();
  const [gstNo, setGstNo] = useState();
  const [stCh, setStCh] = useState();
  const [delCh, setDelCh] = useState();
  const [description, setDescription] = useState();

  const [fetchingBill, setFetchingBill] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();

  const [cookies] = useCookies();

  const fetchBillDetails = async (billId) => {
    try {
      setFetchingBill(true);
      const baseUrl = process.env.REACT_APP_BACKEND_URL;
      const response = await fetch(baseUrl + `bill/get?billId=${billId}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${cookies?.token}`,
        },
      });
      const data = await response.json();
      if (!data.success) {
        throw new Error(data.message);
      }
      setBilledToAddress(data?.consignee_address || "N/A");
      setBilledToName(data?.consignee_address || "N/A");
      setBillNo(data?.bill_no || "N/A");
      setDate(data?.date || "N/A");
      setChallanNo(data?.challan_no || "N/A");
      setLorryNo(data?.truck_no || "N/A");
      setWeight(data?.weight || "N/A");
      setBalancePaid(data?.balance || "N/A");
      setAdvancePaid(data?.advance || "N/A");
      setTotal(data?.total || "N/A");
      setGstNo(data?.gst_no || "N/A");
      setDelCh(data?.del_ch || "N/A");
      setStCh(data?.st_ch || "N/A");
      setDescription(data?.description || "N/A");
      setPriceType(data?.price_type || "N/A");
      setPriceTotal(data?.total || "N/A");
      setFrom(data?.from || "N/A");
      setTo(data?.to || "N/A");
      setBiltyNo(data?.bilty_no || "N/A");
      setContents(data?.contents || "N/A");
      setBookingStation(data?.consignee_address || "N/A");
    } catch (err) {
      toast({
        position: "top",
        status: "error",
        description: err.message,
      });
    } finally {
      setFetchingBill(false);
    }
  };

  useEffect(() => {
    if (location?.state?.billId) {
      fetchBillDetails(location?.state?.billId);
    }
  }, []);

  return (
    <div className="py-5 px-5 h-[inherit] overflow-auto">
      <h1 className="text-2xl md:text-4xl mt-5 mb-6 font-bold flex gap-2">
        <IoMdArrowRoundBack onClick={() => navigate("/dashboard/all-bill")} />
        Bill Details
      </h1>

      {fetchingBill && <Loader />}
      {!fetchingBill && (
        <div className="mt-8">
          <div>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-2 mb-1 mt-2">
              <div>
                <p className="font-medium">Bill No.</p>
                <p>{billNo}</p>
              </div>
              <div>
                <p className="font-medium">Challan No.</p>
                <p>{challanNo}</p>
              </div>
              <div>
                <p className="font-medium">Bilty No.</p>
                <p>{biltyNo}</p>
              </div>
              <div>
                <p className="font-medium">From</p>
                <p>{from}</p>
              </div>
              <div>
                <p className="font-medium">To</p>
                <p>{to}</p>
              </div>
              <div>
                <p className="font-medium">Lorry No.</p>
                <p>{lorryNo}</p>
              </div>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-2 mb-1 mt-2">
              <div>
                <p className="font-medium">Date</p>
                <p>{moment(date).format("DD-MM-YYYY")}</p>
              </div>
              <div>
                <p className="font-medium">Contents</p>
                <p>{contents}</p>
              </div>
              <div>
                <p className="font-medium">Booking Station</p>
                <p>{bookingStation}</p>
              </div>
              <div>
                <p className="font-medium">Weight (In Tonne)</p>
                <p>{weight}</p>
              </div>
              <div>
                <p className="font-medium">Billed To (Name)</p>
                <p>{billedToName}</p>
              </div>
              <div>
                <p className="font-medium">Billed To (Address)</p>
                <p>{billedToAddress}</p>
              </div>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-2 mb-1 mt-2">
              <div>
                <p className="font-medium">Description</p>
                <p>{description}</p>
              </div>
              <div>
                <p className="font-medium">Price Type</p>
                <p>{priceType}</p>
              </div>
              <div>
                <p className="font-medium">Total</p>
                <p>{priceTotal}</p>
              </div>
              <div>
                <p className="font-medium">GST No.</p>
                <p>{gstNo}</p>
              </div>
              <div>
                <p className="font-medium">St. Ch.</p>
                <p>{stCh}</p>
              </div>
              <div>
                <p className="font-medium">Del. Ch.</p>
                <p>{delCh}</p>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default BillDetails;
