import { Link } from "react-router-dom";
import logo from "../assets/logo/logo.png";
import { IoCloseCircleSharp } from "react-icons/io5";

const MobileNavigation = ({ setOpenMobileNavigation }) => {
  return (
    <div className="w-full h-[100vh] flex justify-center py-4">
      <div className="font-lg leading-8 w-full">
        <div className="flex justify-between items-center px-4">
          <img className="w-[100px]" src={logo}></img>
          <span
            onClick={() => setOpenMobileNavigation(false)}
            className="hover:scale-105 text-[#fb5f5f]"
          >
            <IoCloseCircleSharp size={25} />
          </span>
        </div>
        <ul className="px-4 mt-4">
          <Link
            onClick={() => setOpenMobileNavigation(false)}
            className="px-2 py-1 hover:bg-[#e7e7e7] rounded block"
            to=""
          >
            All Bilty
          </Link>
          <Link
            onClick={() => setOpenMobileNavigation(false)}
            className="px-2 py-1 hover:bg-[#e7e7e7] rounded block"
            to="all-challan"
          >
            All Challan
          </Link>
          <Link
            onClick={() => setOpenMobileNavigation(false)}
            className="px-2 py-1 hover:bg-[#e7e7e7] rounded block"
            to="all-bill"
          >
            All Bill
          </Link>
          <Link
            onClick={() => setOpenMobileNavigation(false)}
            className="px-2 py-1 hover:bg-[#e7e7e7] rounded block"
            to="create-bilty"
          >
            Create Bilty
          </Link>
          <Link
            onClick={() => setOpenMobileNavigation(false)}
            className="px-2 py-1 hover:bg-[#e7e7e7] rounded block"
            to="create-challan"
          >
            Create Challan
          </Link>
          <Link
            onClick={() => setOpenMobileNavigation(false)}
            className="px-2 py-1 hover:bg-[#e7e7e7] rounded block"
            to="create-bill"
          >
            Create Bill
          </Link>
        </ul>
      </div>
    </div>
  );
};

export default MobileNavigation;
