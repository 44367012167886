import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import Navigation from "../components/Navigation";
import { Outlet, useNavigate } from "react-router-dom";
import MobileNavigation from "../components/MobileNavigation";
import { useCookies } from "react-cookie";
import { useToast } from "@chakra-ui/react";

const Home = () => {
  const [openMobileNavigation, setOpenMobileNavigation] = useState(false);
  const [cookies] = useCookies();
  const navigate = useNavigate();
  const toast = useToast();

  const loginWithToken = async () => {
    try {
      const response = await fetch(
        process.env.REACT_APP_BACKEND_URL + "auth/login-with-token",
        {
          method: "GET",
          headers: {
            authorization: `Bearer ${cookies?.token}`,
          },
        }
      );
      const data = await response.json();
      if (!data.success) {
        throw new Error(data.message);
      }

      toast({
        description: data.message,
        status: "success",
        position: "top",
      });
    } catch (error) {
      toast({
        description: error.message,
        status: "error",
        position: "top",
      });
      navigate("/");
    }
  };

  useEffect(() => {
    if (cookies?.token) {
      loginWithToken();
    } else {
      navigate("/");
    }
  }, []);

  return (
    <div className="relative flex flex-col h-[100vh]">
      <Header setOpenMobileNavigation={setOpenMobileNavigation} />
      {openMobileNavigation && (
        <div className="block lg:hidden absolute top-0 left-0 w-full h-[100vh] bg-white z-50">
          <MobileNavigation setOpenMobileNavigation={setOpenMobileNavigation} />
        </div>
      )}
      <div className="flex flex-1 gap-x-2 mt-5 h-full px-5 py-5">
        <div className="hidden lg:block w-[16rem] h-[inherit]">
          <Navigation />
        </div>
        <div
          className="flex-1 h-[80vh] overflow-auto mt-2"
          style={{ boxShadow: "0 0 10px 5px #f3f3f3" }}
        >
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default Home;
